.checkmail-submit {
    justify-content: center;
    display: flex;
    height: calc(100vh - 145px);
    align-items: center;
    flex-direction: column;
}
.checkmail-submit_form {
    display: block;
}
.checkmail-submit_text {
    margin-bottom: 20px;
    background-image: url(../img/alert-icon.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 20px;
}