.prefix-h1 {
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: left;
	padding-bottom: 20px;
	padding-right: 20px;
	display: flex;
	justify-content: space-between;
}
.react-datepicker {
	box-shadow: 0px 9px 16px 0px #9FA2BF2E;
}
.react-datepicker__day--outside-month {
	display: none;
}
.react-datepicker__month > :first-child{
	text-align: right;
}
.react-datepicker__month > :last-child{
	text-align: left;
}
.prefix__datepicker{
	display: inline-block;
	position: relative;
	width: 150px;
	height: 50px;
	border-right: 1px solid rgba(210, 210, 217, 1);
}

.prefix__switches {
	padding: 21.5px 15px 21px 15px;
	display: inline;
	border-left: none;
}
.prefix__gray-border {
	border: 1px solid #CCCEDD;
	border-radius: 7px;
	background: white;
}
.prefix__switches-wrapper {
    position: relative;
    z-index: 10;
    display: inline-block;
    height: 37px;
    top: -5px;
	/* width: 65px;
	 padding-left: 10px;*/
}
.prefix-info__control{
	margin: 0 auto;
/*	width: calc(100% + 40px);*/
	position: relative;
	/*left: -20px;*/
	z-index: 1;
}
.prefix .react-switch {
	top: -6px;
	left: 50px;
}
.react-switch-handle {
	border: 1px solid #CCCEDD !important;
}
.react-switch-handle[style="height: 18px; width: 18px; background: rgb(26, 117, 255) none repeat scroll 0% 0%; display: inline-block; cursor: pointer; border-radius: 50%; position: absolute; transform: translateX(0px); top: 0px; outline: currentcolor none 0px; box-shadow: rgba(159, 162, 191, 0.32) 0px 2px 2px 0px; border: 0px none; transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;"] {
	border-color: #0061F4 !important;
}
.react-switch-bg {
	background: #CCCEDD;
	box-shadow: 0px 1px 1px 0px #777EA540 inset;
}
.react-date-picker--disabled {
	background-color: transparent;
}
.react-datepicker__input-container input {
	width: 80px;
	font-size: 15px;
}
.react-datepicker__input-container input[disabled=""]{
	background: transparent;
}
.react-datepicker__input-container input:focus-visible{
	border: none;
	outline: 0;
}
.react-datepicker-ignore-onclickoutside {
	width: 125px;
	margin-left: 0px;
}
.react-datepicker__tab-loop {
	display: block;
	position: fixed;
	z-index: 99;
}
.react-datepicker__day--keyboard-selected[aria-disabled="false"],
.react-datepicker__month-text--keyboard-selected[aria-disabled="false"],
.react-datepicker__quarter-text--keyboard-selected[aria-disabled="false"],
.react-datepicker__year-text--keyboard-selected[aria-disabled="false"] {
	background: #1975FF;
	border-radius: 50%;
	padding-left: 4px;
	padding-right: 5px;
	width: 22px;
	height: 22px;
	color: white !important;
}
.react-datepicker__day {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	margin-bottom: 14px;
	margin-right: 6px;

	/* Text color */
	color: #223354;
}
.react-datepicker__day--outside-month {
	border: none;
	background: transparent;
}
.react-datepicker-popper {
	width: 794px;
}
.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
	border: none;
	content: "";
	display: block;
	height: 9px;
	position: absolute;
	top: 6px;
	width: 9px;
	/* Caption */

	position: absolute;
	width: 9.24px;
	height: 9.24px;
	left: 26.43px;
	top: 31.13px;

	/* Primary */
	background: #1A75FF;
}
.react-datepicker__day:hover {
	border-radius: 50%;
}
.react-datepicker__header {
	background-color: transparent;
	border-color: transparent;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;

	/* identical to box height, or 160% */

	align-items: center;
	text-align: center;
	text-transform: uppercase;
}
.react-datepicker__day-name{
	/* secondary text */
	color: #8F9097 !important;
}
.react-datepicker__current-month {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	z-index: 2;
	position: relative;

	/* identical to box height, or 171% */
	align-items: center;
	text-align: center;
}
.prefix-info {
	margin: 0 auto 20px auto;
	padding: 5px;
}
.prefix-info p{
	padding: 0;
	margin-top: 0;
	display: flex;
	justify-content: space-between;
}
.perfix-info__name {
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
}

.prefix-info svg {
	position: static;
	top: 3px;
	z-index: 0;
}
.prefix-info__second-row span{

}

.prefix-info .prefix-info__comment {
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #1A75FF;

}
.prefix-info__first-row {
	margin-bottom: 15px;
}
.prefix-info .prefix-info__as {
	color: #223354;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	display: inline-block;
	float: right;
	margin-right: 10px;
}
.as_length__group {
	/*background: #E5E5E5;*/
	border-radius: 4px;
	padding: 5px 0 6px 5px;
	width: 20%;
/*	margin-right: 5px;*/
	max-width: 300px;
	min-width: 103px;
}
.as_length {
	/*margin-left: 15%;*/
	font-family: Roboto;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
}
.prefix__data-grid__item {
	padding: 15px 10px;
/*	padding-left: 20px;*/
/*	padding-top: 5px; */
	border-bottom: 1px solid rgba(216, 216, 216, 1);
	scroll-snap-type: both;
	flex-direction: column;
	scrollbar-width: none;
	box-sizing: unset;
	/*margin-left: 11px;*/
	flex-direction: row;
	display: flex;
	flex-flow: row wrap;
}
.prefix__data-grid__item__first-row {
	/*float: left;*/
	width: 400px;
	min-width: 400px;
	margin-bottom: 15px;
	display: flex;
	flex-flow: row nowrap;
}
.prefix__datagrid {
	/*max-width: 1024px;
	max-height: 500px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-left: 10px;
	/*width: 1000%;*/
	/*	border-left: 1px solid rgba(216, 216, 216, 1);
		border-right: 1px solid rgba(216, 216, 216, 1);*/
}
.prefix__data-grid__item__first-row > svg {
	position: relative;
	top: 3px;
}
.prefix__data-grid__item__first-row > span {
	/*width: calc(33.33% - 10px);*/
	display: inline-block;
	min-width: 59px;
	margin-right: auto;
}
.prefix__data-grid__item__first-row__name {
	width: 180px;
}
.prefix__data-grid__item__first-row__length {
	text-align: center;
	width: 145px;
	/*padding-left: 80px;*/
}
.prefix__data-grid__item__first-row__as {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-size: 15px;
	line-height: 17px;
	color: gray;

}
.grey {
	color: grey;
}
.red {
	color: red;
}
.lightred {
	/*color: lightpink;*/
	color: #CC0020;
	background-color: #FFE8EB;
	border-radius: 6px;
	/*padding-left: 5px;
	padding-right: 6px;*/
	min-width: 20px;
}
.green {
	color: darkgreen;
}
.lightgreen {
	background: rgba(229, 255, 217, 1);
	color: rgba(48, 144, 0, 1);
	border-radius: 6px;
	min-width: 321px;
}
.prefix__data-grid__item__second_row {
	text-align: left;
	/*width: 1000em;*/
	margin-bottom: 15px;
	margin-top: 3px;
	display: flex;
	flex-direction: row-reverse;
	overflow-x: auto;
	scrollbar-width: thin;
	scroll-snap-align: end;
	scroll-snap-type: x proximity;
}
.prefix__data-grid__item__second_row__path_item0 {
	display: inline-block;
	width: 75px;
	text-align: right;
}
.prefix__data-grid__item__second_row__path_item1 {
	display: inline-block;
	min-width: 20px;
	/*	margin-right: 20px;*/

}
.prefix__data-grid__item__first-row__length-old {
	display: inline-block;
	width: 20px;
	text-align:right;
	margin-right: 20px;
}
.prefix__data-grid__item__first-row__length-new {
	color: red;
}
.react-datepicker__navigation {
	background-image: url("../img/month-navigation.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto;
}
.react-datepicker__navigation--next {
	transform: rotate(180deg) !important;
	transform: scale(1, -1);
}
.prefix-info__control {
/*	background: #E6E7EE;*/
	background: rgba(246, 248, 251, 1);
	border-top: 1px solid lightgray;
	border-bottom: 1px solid lightgray;
	height: 62px;
	padding-top: 5px;
	padding-bottom: 15px;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scrollbar-width: thin; /* Firefox */
	overflow-y: hidden;

}
.prefix-info__control__field {
	height: 52px;

}
.prefix-info__sort__wrapper {
	display: flex;

}
.prefix-info__sort__item {
	scroll-snap-align: start;
	display: inline;
}
.react-datepicker__triangle {
	/*display: none !important; */

}

@media (max-width: 800px){
	.react-datepicker__triangle:before,  .react-datepicker__triangle:after {
		left: 40px !important;
	}
	.react-datepicker-popper {
		margin-left: 100px;
	}
}
@media (max-width: 640px){
	.prefix-h1-desktop {
		font-size: 24px !important;
		padding-left: 5px;
	}
	.group--prefix .container {
/*		padding: 0 5px !important;*/
		min-width: 320px;
	}
	.as_length__group {
		display: inline-flex;
	}
	.prefix__data-grid__item {
		display: flex;
	}
	.prefix__data-grid__item__first-row {
		float: none;
	}
	.prefix__data-grid__item__second_row:hover {
	/*	border: 1px solid grey;*/
	}
	.prefix__data-grid__item__second_row {
		text-align: right;
		width: 500px;
		display: flex;
		overflow-x: auto;
		scroll-snap-type: x mandatory;
	}
	.prefix__data-grid__item__first-row__as {
		text-align: right;
		width: calc(33.33% - 50px);
	}
	.prefix__datagrid__container {
		box-shadow: none !important;
		border: none !important;
		border-radius: 0;
	}
	.prefix__data-grid__item__first-row {
		margin-bottom: 0px;
		display: flex;
		flex-wrap: nowrap;
	}
	h1 .prefix__calendar-icon {
		top: 3px;
	}
}
@media (max-width: 440px){
	.prefix__data-grid__item__first-row {
		width: 350px;
		min-width: 350px;
		margin-bottom: 0px;
		display: flex;
		flex-wrap: wrap;
	}
}
.prefix__group__datepicker {
	float: right;
    display: block;
    margin-top: 3px;
}
.compare-icon{
	position: relative;
	top: 7px;
	margin-right: 5px;
}
.prefix__direction__icons {
	display: inline-block;
	background: Gainsboro;
	height: 52px;
	padding-top: 16px;
	border:1px solid grey;
	border-right: none;
	border-radius:  4px  0 0 4px;
}

.prefix-info__control__field {
	background: Gainsboro;
	border: 1px solid grey;
	border-left: none;
	border-radius:  0 4px 4px 0 ;
}
.prefix__sort {
	background: white;
	padding: 10px;
	border-radius: 4px;
	margin-top: 6px;
	margin-left: 5px;
	width: 40px;
	height: 40px;
	display: inline-block;
}
.prefix-info__sort__wrapper {
	width: 100%;
	padding: 15px 0 0 0;
	/*margin-left: 10px;*/
/*	min-width: 525px;*/
}
.prefix__data-grid__item__first-row__name {
	margin-left: 30px;
}
.prefix__data-grid__item__second_row > span,
.prefix__data-grid__item__three_row > span {
	text-align: right;
	display: flex;
	width: 90px;
}
.prefix__data-grid__item__second_row:last-child {
	scroll-snap-align: end;
}
.prefix__sort-page {
	background-color: white;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index:20;
	transition: 1s;
}
.custom-header {
	height: 40px;
	background-color: rgba(27, 43, 64, 1);
}
.custom-header-none {
	overflow: hidden;
	transition: 1s;
	top: 0;
	left: -100%;
	z-index: 0;
	position: fixed;
	overflow: scroll;
}
.custom-header__button-back {
	padding-top: 10px;
	padding-bottom: 9px;
	margin-left: 10px;
	display: inline-block;
}
.custom-header__title {
	padding-top: 10px;
	padding-bottom: 9px;
	margin-left: 10px;
	display: inline-block;
	position: relative;
	top: -4px;
	color: white;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: center;
}
.prefix__sort-page__item {
	color: rgba(34, 51, 84, 1);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	margin-left: 20px;
}
.prefix__sort-page__item svg{
	margin-right: 10px;
}
.prefix__sort-page__item__text {
	margin-right: 10px;
	line-height: 30px;
	position: relative;
	top: -6px;
}
.prefix-h1-desktop {
	font-family: Roboto;
	font-size: 45px;
	font-style: normal;
	font-weight: 700;
	line-height: 53px;
	letter-spacing: 0em;
	max-width: 1024px;
	width: calc(100% - 20px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 36px;
	flex-wrap: wrap;
}
.prefix-info-desktop {
	max-width: 1024px;
	width: 100%;
	/*	border: 1px solid rgba(224, 224, 224, 1);
		border-radius: 7px 7px 0 0; */
}
.prefix-info-desktop .prefix-info__first-row {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
}
h1 .prefix__group__datepicker {
/*	margin-left: auto;*/
	display: flex;
	align-items: center;
	margin-top: 3px;
	height: 50px;
	width: 200px;
	border: 1px solid rgba(210, 210, 217, 1);
	border-radius: 3px;
}
h1 .prefix__calendar-icon {
	position: relative;
	/*top: 3px;*/
	left: 16px;
}
h1 .react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
	position: relative;
	top: -7px;
	left: 25px;
}
.react-datepicker__input-container input {
	border: none;
}
.prefix-info-desktop .prefix-info__name {
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	/*color: darkslateblue;**/
	color: rgba(34, 51, 84, 1);
	width: calc(100% - 10px);
	display: inline-block;
	overflow-wrap: unset;
	/*margin-left: 20px;*/
	overflow-x: auto;
}
.prefix-info-desktop .prefix-info__as {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 20px;
	display: flex;
	align-items: center;
	/*color: #223354;*/
	color: gray;
	margin-left: 20px;
	width: 100px;
	display: inline-block;
}
.prefix-info-desktop .as_length__group {
	width: 200px;
	/*background: rgba(242, 242, 242, 1);*/
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 18px;
	padding: 10px;
/*	margin-right: 10px;*/
	margin-top: 3px;
	border: 1px solid lightgray;
	/* identical to box height */
	display: flex;
	align-items: center;
	justify-content: space-around;

	/* Color States/Common/Black */
	color: #223354;
}
.prefix-info-desktop .as_length {
	/*margin-right: 15%;
	margin-left: 9%;
	width: 5px; */
	display: inline-block;
}
.prefix-info-desktop .as_length__group  svg{
	flex-shrink: 0.1;
}
.prefix__group__datepicker .compare-icon__blue-white {
	position: relative;
	top: -55px;
	left: 143px;
	z-index: 2;
}
.prefix-info-desktop .prefix-info__second-row {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	padding-left: 20px;
	/* identical to box height */
	display: flex;
	align-items: center;

	/* Primary */
	color: #1A75FF;
}
.prefix-info__control__desktop {
	/*min-width: 660px;*/
	background: rgba(246, 248, 251, 1);
	border-top: 1px solid lightgray;
	border-bottom: 1px solid lightgray;
	/*border-left: 1px solid lightgray;*/
/*	width: 100%;*/
	overflow: auto;
	position: sticky;
	top: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
}
.prefix__sort_desktop__column {
	margin-left: 10px;
	padding-bottom: 15px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 17px;
}
.prefix__sort_desktop__column:nth-child(2) {
/*	width: 100%;*/
	overflow: auto;
	min-width: 430px;
}
.prefix__datagrid__container {
	/*box-shadow: 0px 9px 16px 0px #9FA2BF2E;
	max-width: 1024px;
	padding-right: 5px;*/
	border: 1px solid rgba(224, 224, 224, 1);
	border-radius: 7px;
	/*margin-right: 20px;*/
	/*overflow: hidden;*/
}
.prefix__address-bottom {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	margin-top: 45px;
}
.prefix__ip {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	color: #1A75FF;
}
@media (max-width: 800px){
	h1 .prefix__group__datepicker {
		margin-left: 0;
		position: relative;
	/*	top: -10px;*/
	}
	.prefix__datepicker {
		top: 0;
	}
	.prefix__group__datepicker .compare-icon__blue-white {
		top: -30px;
		left: 144px;
	}
	h1 .react-datepicker-wrapper {
		top: 7px;
	}
	h1 .prefix__calendar-icon {
		/*top: 14px;*/
	}
}
.container {
	width: auto !important;
	/*max-width: 100% !important;*/
}
.group {
	/*width: 100% !important;*/
}
/*
@media (max-width:  879px){
	h1 .react-datepicker-wrapper{
		top: -10px;
	}
	h1 .prefix__calendar-icon {
		top: -11px;
	}
}*/
.prefix__data-grid__item__wrapper {
	display: block;
	justify-content: center;
	border-bottom: 1px solid lightgrey;
	border-collapse: collapse;
}
.w9px {
	min-width: 19.5px;
	display: inline-block;
}
.count_hops{
	display: inline-block;
	min-width: 20px;
}
.prefix__data-grid__item__wrapper:last-child {
	border-bottom: none;
}
.btn-copy-wrapper {
	padding-left: 5px;
	height: 0;
	position: relative;
}
.btn-copy {
	/*content: " ";*/
	padding: 0;
	margin: 0 5px 0;
	position: absolute;
	/*top: -20px; - задаётся в PrefixDataGridRow.js */
	left: 0;
	width: 10px;
	height: 14px;
	background-color: transparent;
	box-shadow: 3px 2px 0 #777;
	border-radius: 3px;
	border: 1px solid #555;
}
