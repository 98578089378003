.container {
	max-width: 1024px;
	width: calc(100% - 40px) !important;
	min-width: 320px;
	margin: 0 auto;
}
.about__bottom-border {
	max-width: 1024px;
	/*width: calc(100% - 40px) !important;*/
	min-width: 320px;
}
.about__bottom-border{
	max-width: 1024px;
	width: 100% ;
	min-width: 320px;
}
.secondary-title {
	max-width: 1024px;
	width: calc(100% - 56px) !important;
}
.about__form {
    max-width: 520px;
	width: calc(100% - 56px) !important;
	min-width: 290px;
}
.textarea {
	max-width: 520px;
	/*width: calc(100% - 56px) !important;
	min-width: 300px;*/
}
.field, .about__button__send {
	min-width: 290px;
	max-width: 320px;
}
.container {
	width: auto !important;
	/*max-width: none !important;*/
}
